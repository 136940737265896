import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const BREVO_API_KEY = process.env.GATSBY_BREVO_API_KEY;

export default function PartnersForm() {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t`partners.contact-form-name-required`),
    company: Yup.string().required(t`partners.contact-form-company-required`),
    email: Yup.string()
      .email(t`partners.contact-form-mail-invalid`)
      .required(t`partners.contact-form-mail-required`),
    phone: Yup.string().required(t`partners.contact-form-phone-required`),
    partnership: Yup.string(),
    message: Yup.string().required(t`partners.contact-form-text-required`)
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        'https://api.brevo.com/v3/smtp/email',
        {
          sender: { email: 'no-reply@innovationshub.pl' },
          to: [{ email: 'hello@innovationshub.pl' }],
          replyTo: { email: values.email },
          subject: 'Strona InnovationsHub - Formularz Partnerski',
          htmlContent: `
            <p>Imię i nazwisko: ${values.name}</p>
            <p>Firma: ${values.company}</p>
            <p>Email: ${values.email}</p>
            <p>Telefon: ${values.phone}</p>
            <p>Obszar zainteresowania: ${values.partnership}</p>
            <p>Wiadomość: ${values.message}</p>
          `
        },
        {
          headers: {
            'api-key': BREVO_API_KEY,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.status === 201) {
        setStatus(t`partners.contact-form-accept`);
        setError(null);
      } else {
        setStatus(null);
        setError(t`partners.contact-form-error`);
      }
    } catch (error) {
      setStatus(null);
      setError(t`partners.contact-form-error`);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={{
        name: '',
        company: '',
        email: '',
        phone: '',
        partnership: 'MedBiz Innovations Program',
        message: ''
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <div className="form-wrapper">
          <Form>
            <label className="form-item-wrapper">
              <span>{t`partners.contact-form-name`}</span>
              <Field
                placeholder={t`partners.contact-form-name-ph`}
                id="name"
                name="name"
              />
              <ErrorMessage name="name" component="div" className="error" />
            </label>
            <label className="form-item-wrapper">
              <span>{t`partners.contact-form-company`}</span>
              <Field
                id="company"
                name="company"
                placeholder={t`partners.contact-form-company-ph`}
              />
              <ErrorMessage name="company" component="div" className="error" />
            </label>
            <label className="form-item-wrapper">
              <span>{t`partners.contact-form-mail`}</span>
              <Field
                id="email"
                className="required email"
                name="email"
                type="email"
                placeholder={t`partners.contact-form-mail-ph`}
              />
              <ErrorMessage name="email" component="div" className="error" />
            </label>
            <label className="form-item-wrapper">
              <span>{t`partners.contact-form-phone`}</span>
              <Field
                placeholder={t`partners.contact-form-phone-ph`}
                id="phone"
                name="phone"
              />
              <ErrorMessage name="phone" component="div" className="error" />
            </label>
            <label className="form-item-wrapper">
              <span>{t`partners.contact-form-scope`}</span>
              <Field component="select" id="partnership" name="partnership">
                <option value="MedBiz Innovations Program" default>
                  MedBiz Innovations Program
                </option>
                <option value="Innovations Incubator">
                  Innovations Incubator
                </option>
                <option value="Innovations Academy">Innovations Academy</option>
                <option value="Innovations Platform">
                  Innovations Platform
                </option>
                <option value="Cała fundacja">{t`partners.contact-form-scope-all`}</option>
              </Field>
              <ErrorMessage
                name="partnership"
                component="div"
                className="error"
              />
            </label>
            <label className="form-item-wrapper">
              <span>{t`partners.contact-form-text`}</span>
              <Field
                as="textarea"
                rows="6"
                placeholder={t`partners.contact-form-text-ph`}
                id="message"
                name="message"
              />
              <ErrorMessage name="message" component="div" className="error" />
            </label>
            <p className="rodo mb-2-5rem">
              {t`partners.contact-form-rodo-1a`}
              <a href="/rodo">{t`partners.contact-form-rodo-1b`}</a>
              {t`partners.contact-form-rodo-1c`}
              <a href="/rodo">{t`partners.contact-form-rodo-1d`}</a>.
            </p>
            <span>
              {error && <p className="error mb-1rem">{error}</p>}
              {status && <p className="status mb-1rem">{status}</p>}
            </span>
            <Field
              name="submit"
              className="form-button"
              type="submit"
              value={t`partners.contact-form-send`}
              disabled={isSubmitting}
            />
          </Form>
        </div>
      )}
    </Formik>
  );
}
