import React, { useState, useEffect } from 'react';
import '../styles/mentoring-questions.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';

function Questions({ location }) {
  const { t } = useTranslation();
  const path = location.pathname;

  const [isMentoring, setIsMentoring] = useState('');

  useEffect(() => {
    setIsMentoring(true);
    if (!path.includes('program-inkubacyjny')) setIsMentoring(false);
  }, [isMentoring]);

  return (
    <>
      <div className="mq-ctn">
        <div className="container mq-text-ctn">
          <h3>
            {isMentoring
              ? t`mentoring.title-mq`
              : t`partners.contact-Juliet-title`}
          </h3>
          {isMentoring && (
            <>
              <h4 className="mb-1rem">{t`mentoring.text-1-mq`}</h4>
              <p className="mb-1rem">
                <a
                  href={t`mentoring.email-address-mq`}>{t`mentoring.email-text-mq`}</a>
              </p>
              <h4 className="mb-1rem">{t`mentoring.text-2-mq`}</h4>
              <p className="mb-1rem">
                {' '}
                <Link to="/regulations/mentoring-program">{t`mentoring.regulations-mq`}</Link>
              </p>
            </>
          )}
          {isMentoring && <h4 className="mb-1rem">{t`mentoring.text-3-mq`}</h4>}
          <p className="mb-1rem">
            {isMentoring ? (
              <Link to="/registration">{t`mentoring.registration-mq`}</Link>
            ) : (
              <a href="mailto: mbadura@innovationshub.pl">
                mbadura@innovationshub.pl
              </a>
            )}
            {!isMentoring && (
              <>
                <br />
                <a
                  href="https://www.linkedin.com/in/mateusz-badura"
                  target="blank"
                  className="mb-1rem">
                  www.linkedin.com/in/mateusz-badura
                </a>
              </>
            )}
          </p>
        </div>
        <div className="initiative-media-ctn incubator-media-ctn">
          {isMentoring ? (
            <StaticImage
              src="../../../images/mentoring/questions.png"
              alt="Any questions?"
              placeholder="none"
              loading="eager"
              quality={100}
              className="questions_img"
            />
          ) : (
            <StaticImage
              src="../../../images/partnerzy/questions3.png"
              alt="Any questions?"
              placeholder="none"
              loading="eager"
              quality={100}
              className="questions_img"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Questions;
