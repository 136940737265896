import './mentoring.css';
import '../new-mentoring/styles/why-participate.css';
import '../../styles/variables.css';
import './styles/main.scss';

import * as React from 'react';

import Card from './components/card';
import CardsGrowth from './components/cardsGrowth';
import CardsSix from './components/cardsSix';
import { FullBtn } from '../new-home/components/home-buttons';
import Layout from '../../components/layout/layout';
import MentoringPartners from '../new-mentoring/components/mentoring-partners';
import PartnersForm from './components/form';
import Questions from '../new-mentoring/components/mentoring-questions';
import Seo from '../../components/seo';
import Slider from 'react-slick';
import { StaticImage } from 'gatsby-plugin-image';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

function PartnersComponent({ location }) {
  const { t } = useTranslation();
  return (
    <Layout withHeader={false}>
      <Seo
        title={t('partners.seo.title')}
        description={t('partners.seo.desc')}
        ogImage={ogImage}
      />
      <div className="mentoring-new-ctn partners">
        <section className="container become-a-partner">
          <h2 className="mb-1rem"> {t`partners.title`}</h2>
          <p className="mb-3rem">
            {t`partners.subtitle-1`} <br />
            {t`partners.subtitle-2`}
          </p>
          <div className="btn-wrapper">
            <FullBtn to="#become-a-partner">Zostań Partnerem</FullBtn>
          </div>
        </section>
        <section className="container mb-5rem">
          <h2>{t`partners.why-to-become-title`}</h2>
          <div className="card-flex">
            <CardsGrowth page="partners" />
          </div>
        </section>
        <section className="container dark">
          <section className="container">
            <h2 className="white mb-3rem">
              {t`partners.cooperate-title-1`}
              <br />
              {t`partners.cooperate-title-2`}
            </h2>
            <div className="card-section">
              <div className="highlight">
                <Card
                  tag={'h3'}
                  wrapper={'white-border custom-padding'}
                  content={true}
                  headerClass={'mb-1rem'}
                  header={t`partners.cooperate-benefit-1-title`}
                  textClass="card-highlight"
                  text={t`partners.cooperate-benefit-1-text`}
                  extrasClass="btnCardCenter"
                />
              </div>
              <div className="spacer-plus">
                <StaticImage
                  src="../../images/partnerzy/Add_ring.png"
                  alt="ring"
                />
              </div>
              <div className="cards">
                <CardsSix></CardsSix>
              </div>
            </div>
          </section>
        </section>
        <MentoringPartners location={location} />
        <section className="partners-slider">
          <Slider
            className="partners-slider-ctn"
            dots={true}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={true}
            autoplaySpeed={4000}
            autoplay={true}>
            <div className="partners-slider-item">
              <div className="partners-slider-image">
                <StaticImage
                  src="../../images/partnerzy/rajmund-martyniuk.jpg"
                  alt="Ireneusz Martyniuk"
                  loading="eager"
                  quality={100}
                  className="partner-round"
                />
              </div>
              <div className="partners-slider-text">
                <div className="p-s-testimonial">
                  <p>{t`partners.testimony-1-quote`}</p>
                </div>
                <div className="p-s-role">
                  <p>
                    {t`partners.testimony-1-author-name`} <br />
                    {t`partners.testimony-1-author-position`}
                  </p>
                </div>
              </div>
            </div>
            <div className="partners-slider-item">
              <div className="partners-slider-image">
                <StaticImage
                  src="../../images/partnerzy/ligia-kornowska.jpg"
                  alt="Ligia Kornowska"
                  loading="eager"
                  quality={100}
                  className="partner-round"
                />
              </div>
              <div className="partners-slider-text">
                <div className="p-s-testimonial">
                  <p>{t`partners.testimony-2-quote`}</p>
                </div>
                <div className="p-s-role">
                  <p>
                    {t`partners.testimony-2-author-name`}
                    <br />
                    {t`partners.testimony-2-author-position`}
                  </p>
                </div>
              </div>
            </div>
            <div className="partners-slider-item">
              <div className="partners-slider-image">
                <StaticImage
                  src="../../images/partnerzy/Grzegorz-Piestrak.jpg"
                  alt="Grzegorz Piestrak"
                  loading="eager"
                  quality={100}
                  className="partner-round"
                />
              </div>
              <div className="partners-slider-text">
                <div className="p-s-testimonial">
                  <p>{t`partners.testimony-3-quote`}</p>
                </div>
                <div className="p-s-role">
                  <p>
                    {t`partners.testimony-3-author-name`}
                    <br />
                    {t`partners.testimony-3-author-position`}
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </section>
        <section className="container partners-form" id="become-a-partner">
          <h2>{t`partners.contact-form-title`}</h2>
          <PartnersForm />
        </section>
        <Questions location={location} />
      </div>
    </Layout>
  );
}

export default PartnersComponent;
